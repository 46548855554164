import { useEventListener, useScroll } from '@vueuse/core'
import type { DirectiveBinding } from 'vue'

/**
 * vFocusScroll Directive
 *
 * This directive ensures that when an focusable element within a specified container
 * receives focus, the page scrolls if necessary to make the focused element fully
 * visible without being obscured by a sticky element.
 *
 * Usage:
 *
 * To use the vFocusScroll directive, you need to register it in your Vue component
 * and apply it to a parent element.
 *
 * import { vFocusScroll } from '@design-system/directives/focusScroll'
 *
 *
 * In your template:
 *
 * <div v-focus-scroll="'sticky-element-class'">
 *   <!-- Your content here -->
 * </div>
 *
 * Parameter:
 *   - stickyElementClassName (string): The class name of the sticky element that might obscure the focused element.
 */

export const vFocusScroll = {
  mounted: (
    el: HTMLElement,
    { value: stickyElementClassName }: DirectiveBinding<string>
  ) => {
    useEventListener(
      'focus',
      (e: FocusEvent) => {
        const stickyElement = el.querySelector('.' + stickyElementClassName)
        const target = e.target as HTMLElement
        const windowHeight = window.innerHeight
        const { y: documentY } = useScroll(window)
        if (stickyElement && e.target instanceof HTMLElement) {
          if (stickyElement.contains(target)) {
            return
          }
          const barHeight = stickyElement.clientHeight || 0
          const focusBottom = target.getBoundingClientRect().bottom
          if (focusBottom > windowHeight - barHeight) {
            const scrollTo =
              documentY.value + focusBottom - (windowHeight - barHeight)
            window.scrollTo({
              top: scrollTo,
            })
          }
        }
      },
      true
    )
  },
}
